.container {
  @apply flex justify-center;
}

.leftSide {
  @apply h-max p-6 rounded-xl bg-white w-[360px] sticky top-[100px] right-[-20px] transition-[height] duration-500 ease-in-out
}

.box {
  @apply pr-[300px];
  @apply pb-[40px];

  @media screen and (max-width: 1600px) {
    @apply flex-col pr-0;
  }
}