.buildersBottom {
  display: none;
}

@media screen and (max-width: 991px) {
  .buildersTop {
    display: none;
  }

  .buildersBottom {
    display: block;
  }
}