.category {
    gap: 0 !important;

    >h2 {
        font-size: 24px;
        font-weight: 600;
        line-height: 29.05px;
        text-align: left;
        margin-bottom: 20px;
        color: #2c2c2c;
    }

    @media screen and (max-width: 767px) {
        >h2 {
            font-size: 18px;
        }
    }

    &_item {
        padding: 24px;
        border-radius: 12px;
        background: #ffffff;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 768px) {
            padding: 12px;
        }
    }

    .faq_item {
        margin-bottom: 16px;
        border-bottom: 1px solid #eaeaea;
        overflow: hidden;

        &[open] .arrow_icon {
            transform: rotate(-90deg);
        }
    }

    .faq_summary {
        padding: 16px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 600;
        line-height: 21.78px;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            font-size: 18px;
            font-weight: 500;
        }
    }

    .arrow_icon {
        transition: transform 0.3s;
    }

    .faq_content {
        padding: 16px;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;

        @media screen and (max-width: 768px) {
            font-size: 16px;
            color: #616161;
        }
    }
}